import {
	Call,
	CallAgent,
	CallEndReason,
	CallState,
	CollectionUpdatedEvent,
	IncomingCall,
	LocalVideoStream,
	RemoteParticipant,
	RemoteVideoStream,
	StartCallOptions,
} from '@azure/communication-calling'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { AzureLogger, setLogLevel } from '@azure/logger'
import React, { MutableRefObject } from 'react'
import {
	getAzureCommunicationTokenApi,
	getUserOnlineStatusApi,
	logAzureCallApi,
} from '../../apiCalls'
import appConfig from '../../config'
import { completedExamStatus } from '../../libs/exams'
import {
	delIntersessionAzureCommunicationData,
	setIntersessionAzureCommunicationData,
} from '../../libs/intersession'
import {
	AzureCommunicationData,
	AzureLogger as AzureLoggerModel,
} from '../../model/azureCommunication'
import { ExamApi, StrippedExam } from '../../model/exam'
import { Id } from '../../model/model'
import { Platform } from '../../model/users'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import errorsActions from '../errors/actions'
import notificationsActions from '../notifications/actions'
import { selectNotification } from '../notifications/selectors'
import { selectAzureCommunicationData, selectCallee } from './selectors'
import { slice } from './slice'
import {
	CALL_END_REASON_CODES,
	CallEndReasonCode,
} from './utils/callEndReasonCodes'
import { CALL_AGENT_INIT_STATE } from './utils/models'
import {
	createStatefulCallClient,
	fromFlatCommunicationIdentifier,
	StatefulCallClient,
} from '@azure/communication-react'

type LogArgs = {
	action: string
	store: string
	callId?: string
	displayName: string
	examId?: Id
	error?: Error
}

type CommonArgs = {
	callId?: string
	displayName: string
	examId?: Id
	setCallKnownError: (error?: CallEndReasonCode) => void
}

type InitAzureCallServiceArgs = CommonArgs & {
	callRef: MutableRefObject<Call | undefined>
	examId?: string
	store: string
	userToken: string
	setCall: (call?: Call) => void
	setCallAgent: (ca?: CallAgent) => void
	setIsCallAgentInitProcedureEnded: (state: CALL_AGENT_INIT_STATE) => void
	setIncomingCall: (call?: IncomingCall) => void
	setStatefulCallClient: (statefulCallClient: StatefulCallClient) => void
	userId: string
}

type HandleStartCallArgs = CommonArgs & {
	statefulCallClient: StatefulCallClient
	callAgent: CallAgent
	store: string
	userIdToCall: string
	withVideo: boolean
	setCall: (call?: Call) => void
}

type HandleCallOptionsArgs = {
	statefulCallClient: StatefulCallClient
	withVideo: boolean
}

type DysplayCallEndReasonArgs = {
	callEndReason: CallEndReason
	setCall: (call?: Call) => void
	setCallKnownError: (error?: CallEndReasonCode) => void
	setIncomingCall: (incomingCall?: IncomingCall) => void
}

export type ParticipantStreamTuple = {
	stream: RemoteVideoStream
	participant: RemoteParticipant
	streamRendererComponentRef: React.RefObject<HTMLDivElement>
}

type HandleSubscribeToRemoteParticipantArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	participant: RemoteParticipant
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

type HandleCallStateChangeArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	call: Call
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setCallState: (state: CallState) => void
	setHandleChangeDone: (done: boolean) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

const logAzure = ({
	action,
	callId,
	displayName,
	error,
	examId,
	store,
}: LogArgs) => {
	const log: AzureLoggerModel = {
		action,
		callId: callId || '',
		error: error ? error.message : '',
		examId: examId || '',
		log: window.acsLogBuffer || [],
		platform: 'teleoptometry',
		store,
		userAgent: navigator.userAgent,
		username: displayName,
	}

	logAzureCallApi(log).then(() => {
		window.acsLogBuffer = []
	})
}

const cleanUpAzureCommunication =
	(exams: void | (StrippedExam | ExamApi)[]): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const azureConnection = selectAzureCommunicationData(state)
		if (azureConnection.relatedToExam) {
			const exam = !exams
				? null
				: exams.find(e => e._id === azureConnection.relatedToExam)
			if (!exam || completedExamStatus.includes(exam.status)) {
				dispatch(resetAzureCommunication())
			}
		}
	}

const setAzureCommunication =
	(data: AzureCommunicationData): AppThunk =>
	(dispatch: TeloDispatch) => {
		setIntersessionAzureCommunicationData(data)
		dispatch(slice.actions.setAzureConnectionAuth(data))
	}

const resetAzureCommunication = (): AppThunkPromise => async dispatch => {
	delIntersessionAzureCommunicationData()
	dispatch(slice.actions.delAzureConnectionAuth())
}

const getAzureCommunicationToken = (): AppThunk => (dispatch: TeloDispatch) => {
	dispatch(slice.actions.setAzureConnectionStatus('logging in'))
	getAzureCommunicationTokenApi()
		.then(response => {
			if (!response) {
				dispatch(
					errorsActions.setLoginError({
						message: 'invalid azure connection',
						details: 'Azure Communication login error. Invalid response.',
					}),
				)
				return
			}

			const { token, expiresIn, communicationUserId } = response
			if (token) {
				const loginData = {
					token,
					expiresIn,
					communicationUserId,
				}
				setIntersessionAzureCommunicationData(loginData)
				dispatch(slice.actions.setAzureConnectionAuth(loginData))
			} else {
				dispatch(
					errorsActions.setLoginError({
						message: 'azure connection missing token',
						details: 'Azure Communication login error.',
					}),
				)
			}
		})
		.catch(err => dispatch(errorsActions.setUiError(err)))
}

const getCallOptions = async ({
	withVideo,
	statefulCallClient,
}: HandleCallOptionsArgs) => {
	const deviceManager = await statefulCallClient.getDeviceManager()
	await deviceManager.askDevicePermission({
		audio: true,
		video: true,
	})
	const cameras = await deviceManager.getCameras()

	const callOptions: StartCallOptions = {
		videoOptions: {
			localVideoStreams:
				cameras[0] && withVideo
					? [new LocalVideoStream(cameras[0])]
					: undefined,
		},
		audioOptions: {
			muted: false,
		},
	}

	return callOptions
}

const subscribeToRemoteParticipant = ({
	allRemoteParticipantStreams,
	participant,
	remoteParticipants,
	setAllRemoteParticipantStreams,
	setRemoteParticipants,
}: HandleSubscribeToRemoteParticipantArgs) => {
	const rp = remoteParticipants.current.find(p => p === participant)

	if (!rp) {
		setRemoteParticipants([...remoteParticipants.current, participant])
	}

	const addToListOfAllRemoteParticipantStreams = (
		participantStreams: readonly RemoteVideoStream[],
	) => {
		if (!participantStreams || participantStreams.length === 0) {
			return
		}

		let participantStreamTuples = participantStreams.map(stream => {
			return {
				stream,
				participant,
				streamRendererComponentRef: React.createRef<HTMLDivElement>(),
			}
		})

		const updatedStreams = participantStreamTuples.reduce((prev, tuple) => {
			const exists = prev.some(t => t === tuple)

			if (tuple.stream.mediaStreamType === 'ScreenSharing') {
				return prev
			}
			if (!exists) {
				return [...prev, tuple]
			}

			return prev
		}, allRemoteParticipantStreams.current)

		setAllRemoteParticipantStreams(updatedStreams)
	}

	const removeFromListOfAllRemoteParticipantStreams = (
		participantStreams: RemoteVideoStream[],
	) => {
		participantStreams.forEach(streamToRemove => {
			const tupleToRemove = allRemoteParticipantStreams.current.find(
				t => t.stream === streamToRemove,
			)
			if (tupleToRemove) {
				setAllRemoteParticipantStreams(
					allRemoteParticipantStreams.current.filter(t => t !== tupleToRemove),
				)
			}
		})
	}

	const handleVideoStreamsUpdated: CollectionUpdatedEvent<
		RemoteVideoStream
	> = event => {
		addToListOfAllRemoteParticipantStreams(event.added)
		removeFromListOfAllRemoteParticipantStreams(event.removed)
	}

	addToListOfAllRemoteParticipantStreams(participant.videoStreams)
	participant.on('videoStreamsUpdated', handleVideoStreamsUpdated)
}

const initAzureCallService =
	({
		userToken,
		displayName,
		examId,
		callRef,
		store,
		setCall,
		setIsCallAgentInitProcedureEnded,
		setIncomingCall,
		setCallAgent,
		setCallKnownError,
		setStatefulCallClient,
		userId,
	}: InitAzureCallServiceArgs): AppThunkPromise =>
	async (dispatch: TeloDispatch, getState: TeloGetState) => {
		try {
			setIsCallAgentInitProcedureEnded('START')
			setLogLevel('info')

			const statefulCallClient = createStatefulCallClient(
				{
					userId: { communicationUserId: userId },
				},
				{
					callClientOptions: {
						diagnostics: {
							appName: 'teleoptometry',
							appVersion: appConfig.app.version,
						},
					},
				},
			)
			setStatefulCallClient(statefulCallClient)

			const tokenCredential = new AzureCommunicationTokenCredential(userToken)
			const callAgent = await statefulCallClient.createCallAgent(
				tokenCredential,
				{
					displayName,
				},
			)

			window.acsLogBuffer = []

			AzureLogger.log = (...args) => {
				window.acsLogBuffer.push(...args)
				if (args[0].startsWith('azure:ACS:error')) {
					logAzure({
						action: 'AZURE_LOGGER_ERROR',
						store,
						callId: window.callId || '-',
						examId,
						displayName,
					})
				}
			}

			callAgent.on('incomingCall', async ({ incomingCall }) => {
				if (callRef.current) {
					await incomingCall.reject()
					return
				}

				setIncomingCall(incomingCall)

				const callOptions = await getCallOptions({
					statefulCallClient,
					withVideo: true,
				})
				incomingCall.accept(callOptions).then(call => setCall(call))
			})

			setCallAgent(callAgent)
			const deviceManager = await statefulCallClient.getDeviceManager()
			const grants = await deviceManager.askDevicePermission({
				audio: true,
				video: true,
			})
			if (!grants.audio || !grants.video) {
				const issue = CALL_END_REASON_CODES.find(e => e.code === 701)
				if (issue) {
					setCallKnownError(issue)
				}
			}

			setIsCallAgentInitProcedureEnded('END')
		} catch (error) {
			setIsCallAgentInitProcedureEnded('ERROR')
			dispatch(
				showUnknownError(
					'Videocall, failed to start the call: ' + (error as Error).message,
				),
			)
			logAzure({
				action: 'INIT_AZURE_CALL_SERVICE',
				store,
				callId: '',
				examId,
				displayName,
				error: error as Error,
			})
		}
	}

const startCall =
	({
		callAgent,
		displayName,
		examId,
		store,
		userIdToCall,
		withVideo,
		setCallKnownError,
		setCall,
		statefulCallClient,
	}: HandleStartCallArgs): AppThunkPromise =>
	async (dispatch: TeloDispatch, getState: TeloGetState) => {
		try {
			const callOptions = await getCallOptions({
				statefulCallClient,
				withVideo,
			})
			const newCall = callAgent.startCall(
				[fromFlatCommunicationIdentifier(userIdToCall)],
				callOptions,
			)
			setCall(newCall)

			window.callId = newCall.id
			logAzure({
				action: 'START_CALL_OK',
				store,
				callId: newCall.id,
				examId,
				displayName,
			})
		} catch (error) {
			dispatch(
				showUnknownError(
					'Videocall, failed to start the call: ' + (error as Error).message,
				),
			)
			logAzure({
				action: 'HANDLE_START_CALL',
				store,
				callId: '-',
				examId,
				displayName,
				error: error as Error,
			})
		}
	}

const setTeloCallStatus =
	(payload: 'NONE' | 'STARTED' | 'ENDED' | 'FORCE_ENDED'): AppThunk =>
	(dispatch: TeloDispatch) =>
		dispatch(slice.actions.setTeloCallStatus(payload))

const fetchCalleeOnlineStatus =
	(platform: Platform, username?: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const currentCalleeOnlineStatus = selectCallee(state)
		if (!username) {
			username !== currentCalleeOnlineStatus.username &&
				dispatch(
					slice.actions.setCalleeOnlineStatus({ username: '', online: false }),
				)
			return
		}

		getUserOnlineStatusApi(username, platform).then(online => {
			online !== undefined &&
				online !== currentCalleeOnlineStatus.online &&
				dispatch(slice.actions.setCalleeOnlineStatus({ username, online }))
		})
	}

const showUnknownError =
	(callUnknownError: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const notificationId = 'AzureCallUnknownErroor'
		const errorNotification = selectNotification(notificationId)(state)
		if (!errorNotification) {
			dispatch(
				notificationsActions.addNotification({
					id: notificationId,
					type: 'error',
					message: `Videocall: ${callUnknownError}`,
					messageIsLabelKey: true,
					autoClose: false,
				}),
			)
		}
	}

const azureCommunicationActions = {
	...slice.actions,
	cleanUpAzureCommunication,
	getAzureCommunicationToken,
	getCallOptions,
	initAzureCallService,
	resetAzureCommunication,
	setAzureCommunication,
	setTeloCallStatus,
	showUnknownError,
	startCall,
	subscribeToRemoteParticipant,
	fetchCalleeOnlineStatus,
}

export default azureCommunicationActions
