import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '../../styleguide/dialog'
import { Trans, useTranslation } from 'react-i18next'
import Link from '@mui/material/Link/Link'
import Text from '../../styleguide/typography/Text'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import theme from '../../styleguide/theme'
import styled from 'styled-components'
import CircularProgress from '../../styleguide/CircularProgress'
import { useTeloDispatch, useTeloSelector } from '../../store'
import patientExportActions from '../../features/patientExport/actions'
import { getPatientExportDetails } from '../../apiCalls'
import dialogActions from '../../features/dialog/actions'
import {
	Dialog as DialogType,
	PatientDecryptionKeyDialogType,
} from '../../model/dialog'
import { selectDialog } from '../../features/dialog/selectors'
import { useReactToPrint } from 'react-to-print'

type PatientDecryptionKeyDialogProps = {
	exportId: string
	patientFullName?: string
}

const StyledTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${theme.spacing(1)};
`

const StyledDialogTitle = styled.span`
	text-transform: lowercase;
	display: inline-block;
	&::first-letter {
		text-transform: uppercase;
	}
`

const StyledDialogSubtitle = styled.span`
	text-transform: capitalize;
	display: inline-block;
	opacity: 0.64;
`

const printedPageStyle = `@page {
	margin: ${theme.spacing(2)};
}`

const isDecryptionKeyDialog = (
	dialog: DialogType,
): dialog is PatientDecryptionKeyDialogType => dialog.type === 'decryptionKey'

const PatientDecryptionKeyDialog = ({
	exportId,
	patientFullName = '',
}: PatientDecryptionKeyDialogProps) => {
	const [decryptionKey, setDecryptionKey] = useState('')
	const keyTextRef = useRef<HTMLSpanElement>(null)

	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)
	const handlePrint = useReactToPrint({
		contentRef: keyTextRef,
		pageStyle: printedPageStyle,
	})

	const isOpen = isDecryptionKeyDialog(dialog)

	const handleDialogClose = useCallback(
		() => dispatch(dialogActions.closeDialog()),
		[dispatch],
	)

	useEffect(() => {
		getPatientExportDetails(exportId)
			.then(response => {
				dispatch(patientExportActions.removeExportStateById(exportId))
				setDecryptionKey(response.password)
				window.location.assign(response.downloadUrl)
			})
			.catch(e => {
				handleDialogClose()
			})
	}, [dispatch, exportId, handleDialogClose])

	return (
		<Dialog open={isOpen}>
			<DialogTitle onClose={handleDialogClose}>
				<StyledTitleContainer>
					<StyledDialogTitle>
						{t('decryptionKeyDialog.title')}
					</StyledDialogTitle>
					<StyledDialogSubtitle>{patientFullName}</StyledDialogSubtitle>
				</StyledTitleContainer>
			</DialogTitle>
			<DialogContent sx={{ '&.MuiDialogContent-root': { py: 3 } }}>
				<DialogContentText marginBottom={2}>
					<Text variant="body2" marginBottom={5}>
						<Trans i18nKey="decryptionKeyDialog.description">
							.<strong />
						</Trans>
					</Text>
					{decryptionKey ? (
						<Text
							ref={keyTextRef}
							variant="h5"
							fontWeight={theme.typography.fontWeightMedium}
							letterSpacing={theme.spacing()}
							textAlign="center"
						>
							{decryptionKey}
						</Text>
					) : (
						<CircularProgress />
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions justifyContent="flex-start">
				<Link
					component="button"
					variant="body1"
					onClick={() => handlePrint()}
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: theme.spacing(1),
					}}
				>
					<LocalPrintshopOutlinedIcon />
					{t('decryptionKeyDialog.print')}
				</Link>
			</DialogActions>
		</Dialog>
	)
}

export default PatientDecryptionKeyDialog
