import { getActivePatientExport } from '../../apiCalls'
import { NotificationSeverity } from '../../model/model'
import { AppThunk, TeloDispatch } from '../../store'
import dialogActions from '../dialog/actions'
import notificationsActions from '../notifications/actions'
import { ExportState, patientExportSlice } from './slice'

const fetchActivePatientExport =
	(storeId: string): AppThunk =>
	(dispatch: TeloDispatch) =>
		getActivePatientExport(storeId).then(exportState =>
			dispatch(patientExportActions.setExportState(exportState || [])),
		)

const handlePatientExportDone =
	(data: ExportState): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(patientExportActions.addExportState(data))
		dispatch(
			notificationsActions.addNotification({
				isAlert: true,
				titleIsLabelKey: true,
				severity: NotificationSeverity.Info,
				title: 'patient.exportPatientDataReadyTitle',
				autoClose: true,
				actions: {
					secondary: {
						title: 'patient.downloadPatientDataCapitalize',
						onAction: () =>
							dispatch(
								dialogActions.openDialog({
									type: 'decryptionKey',
									exportId: data._id,
								}),
							),
					},
				},
			}),
		)
	}

const patientExportActions = {
	...patientExportSlice.actions,
	fetchActivePatientExport,
	handlePatientExportDone,
}

export default patientExportActions
